var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"v-card--material",attrs:{"color":"secondary"}},[_c('div',{staticClass:"d-flex--v2 grow flex-wrap"},[_c('v-sheet',{staticClass:"text-start v-card--material__heading mb-n6 elevation-6 pa-7",attrs:{"color":"indigo","max-height":"90","width":"auto"}},[_c('v-icon',{attrs:{"large":"","color":"white"}},[_vm._v(" mdi-history ")])],1),_c('div',{staticClass:"ml-6"},[_c('v-card-title',[_c('span',{staticClass:"text-h6 font-weight-light",domProps:{"textContent":_vm._s(_vm.$t('dashboard.activityStream.lastActivity'))}}),(
                            _vm.activityToday ||
                                _vm.activityYesterday ||
                                _vm.activityLastWeek ||
                                _vm.activityElse
                        )?_c('v-btn',{attrs:{"tag":"a","to":{ name: 'ActivityStream' },"text":"","small":"","color":"indigo"},domProps:{"textContent":_vm._s(_vm.$t('more'))}}):_vm._e()],1)],1)],1),_c('v-card-text',[(
                    !_vm.$wait.is('fetch activity stream') &&
                        !_vm.activityToday &&
                        !_vm.activityYesterday &&
                        !_vm.activityLastWeek &&
                        !_vm.activityElse
                )?_c('span',[_vm._v(" "+_vm._s(_vm.$t('dashboard.activityStream.noStream'))+" ")]):_vm._e(),(_vm.$wait.is('fetch activity stream') )?_c('v-skeleton-loader',{staticClass:"mx-auto pt-3",attrs:{"type":'sentences',"tile":"","height":"54"}}):((_vm.activityToday ||
                            _vm.activityYesterday ||
                            _vm.activityLastWeek ||
                            _vm.activityElse)
                )?_c('v-timeline',{staticClass:"ml-2",attrs:{"dense":""}},[(_vm.activityToday)?_c('v-timeline-item',{attrs:{"color":"indigo","small":"","fill-dot":""}},[(_vm.activityToday)?_c('DashboardActivityDateGroupSimple',{attrs:{"dateGroup":_vm.$t('dashboard.dateGroup.' + _vm.activityToday.group),"items":_vm.activityToday.items}}):_vm._e()],1):_vm._e(),(_vm.activityYesterday)?_c('v-timeline-item',{attrs:{"color":"indigo","small":"","fill-dot":""}},[(_vm.activityYesterday)?_c('DashboardActivityDateGroupSimple',{attrs:{"dateGroup":_vm.$t(
                                'dashboard.dateGroup.' +
                                    _vm.activityYesterday.group
                            ),"items":_vm.activityYesterday.items}}):_vm._e()],1):_vm._e(),(_vm.activityLastWeek)?_c('v-timeline-item',{attrs:{"color":"indigo","small":"","fill-dot":""}},[(_vm.activityLastWeek)?_c('DashboardActivityDateGroupSimple',{attrs:{"dateGroup":_vm.$t(
                                'dashboard.dateGroup.' +
                                    _vm.activityLastWeek.group
                            ),"items":_vm.activityLastWeek.items}}):_vm._e()],1):_vm._e(),(_vm.activityElse)?_vm._l((_vm.activityElse),function(activityGroup){return _c('v-timeline-item',{key:activityGroup.group,attrs:{"color":"indigo","small":"","fill-dot":""}},[_c('DashboardActivityDateGroupSimple',{attrs:{"dateGroup":activityGroup.group,"items":activityGroup.items}})],1)}):_vm._e()],2):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }