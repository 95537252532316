<template>
    <div>
        <v-card class="v-card--material" color="secondary">
            <div class="d-flex--v2 grow flex-wrap">
                <v-sheet
                    color="indigo"
                    max-height="90"
                    width="auto"
                    class="text-start v-card--material__heading mb-n6 elevation-6 pa-7"
                >
                    <v-icon large color="white">
                        mdi-history
                    </v-icon>
                </v-sheet>
                <div class="ml-6">
                    <v-card-title>
                        <span
                            class="text-h6 font-weight-light"
                            v-text="$t('dashboard.activityStream.lastActivity')"
                        />
                        <v-btn
                            v-if="
                                activityToday ||
                                    activityYesterday ||
                                    activityLastWeek ||
                                    activityElse
                            "
                            tag="a"
                            :to="{ name: 'ActivityStream' }"
                            text
                            small
                            color="indigo"
                            v-text="$t('more')"
                        />
                    </v-card-title>
                </div>
            </div>
            <v-card-text>
                <span
                    v-if="
                        !$wait.is('fetch activity stream') &&
                            !activityToday &&
                            !activityYesterday &&
                            !activityLastWeek &&
                            !activityElse
                    "
                >
                    {{ $t('dashboard.activityStream.noStream') }}
                </span>
                <v-skeleton-loader
                    v-if="$wait.is('fetch activity stream') "
                    :type="'sentences'"
                    tile
                    class="mx-auto pt-3"
                    height="54"
                ></v-skeleton-loader>
                <v-timeline
                    v-else-if="(activityToday ||
                                activityYesterday ||
                                activityLastWeek ||
                                activityElse)
                    "
                    dense
                    class="ml-2"
                >
                    <v-timeline-item
                        v-if="activityToday"
                        color="indigo"
                        small
                        fill-dot
                    >
                        <DashboardActivityDateGroupSimple
                            v-if="activityToday"
                            :dateGroup="
                                $t('dashboard.dateGroup.' + activityToday.group)
                            "
                            :items="activityToday.items"
                        />
                    </v-timeline-item>
                    <v-timeline-item
                        v-if="activityYesterday"
                        color="indigo"
                        small
                        fill-dot
                    >
                        <DashboardActivityDateGroupSimple
                            v-if="activityYesterday"
                            :dateGroup="
                                $t(
                                    'dashboard.dateGroup.' +
                                        activityYesterday.group
                                )
                            "
                            :items="activityYesterday.items"
                        />
                    </v-timeline-item>
                    <v-timeline-item
                        v-if="activityLastWeek"
                        color="indigo"
                        small
                        fill-dot
                    >
                        <DashboardActivityDateGroupSimple
                            v-if="activityLastWeek"
                            :dateGroup="
                                $t(
                                    'dashboard.dateGroup.' +
                                        activityLastWeek.group
                                )
                            "
                            :items="activityLastWeek.items"
                        />
                    </v-timeline-item>
                    <template v-if="activityElse">
                        <v-timeline-item
                            v-for="activityGroup in activityElse"
                            :key="activityGroup.group"
                            color="indigo"
                            small
                            fill-dot
                        >
                            <DashboardActivityDateGroupSimple
                                :dateGroup="activityGroup.group"
                                :items="activityGroup.items"
                            />
                        </v-timeline-item>
                    </template>
                </v-timeline>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import DashboardActivityDateGroupSimple from './DashboardActivityDateGroupSimple.vue';

export default {
    name: 'DashboardActivities',
    components: {
        DashboardActivityDateGroupSimple,
    },
    i18n: {
        messages: {
            en: require('@/locales/Dashboard/en.json'),
            de: require('@/locales/Dashboard/de.json'),
        },
    },
    data() {
        return {};
    },
    computed: {
        ...mapGetters({
            activityStreamItems: 'dashboard/getGroupedActivityStreamItems',
        }),
        groupCount() {
            return this.activityToday
                ? 1
                : 0 + this.activityYesterday
                ? 1
                : 0 + this.activityLastWeek
                ? 1
                : 0 + this.activityElse
                ? this.activityElse.length
                : 0;
        },
        activityToday() {
            const data = this.activityStreamItems.find(
                x => x.group === 'today'
            );
            if (!data) {
                return null;
            }
            return data.items && data.items.length ? data : null;
        },
        activityYesterday() {
            const data = this.activityStreamItems.find(
                x => x.group === 'yesterday'
            );
            if (!data) {
                return null;
            }
            return data.items && data.items.length ? data : null;
        },
        activityLastWeek() {
            const data = this.activityStreamItems.find(
                x => x.group === 'lastWeek'
            );
            if (!data) {
                return null;
            }
            return data.items && data.items.length ? data : null;
        },
        activityElse() {
            const arrData = this.activityStreamItems.filter(
                x =>
                    x.group !== 'today' &&
                    x.group !== 'yesterday' &&
                    x.group !== 'lastWeek'
            );
            if (!arrData || !arrData.length) {
                return null;
            }
            return arrData;
        },
    },
    created() {
        this.fetchData();
    },
    methods: {
        ...mapActions({
            fetchActivityStream: 'dashboard/fetchActivityStream',
        }),
        async fetchData() {
            this.$wait.start('fetch activity stream');
            await this.fetchActivityStream();
            this.$wait.end('fetch activity stream');
        },
    },
    activated () {
        this.$wait.start('fetch activity stream');
        this.fetchActivityStream();
        this.$wait.end('fetch activity stream');
    },
};
</script>
